<template>
  <div class="privacy-policy">
    <div class="container content has-text-justified" v-html="$t('privacy_policy')">
    </div>

  </div>
</template>

<script>

export default {
  name: 'privacy_policy',
  metaInfo(){
    return {
      link: this.$getAlternates('privacy_policy'),
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
